import { createStore } from 'redux';

// Здесь можно добавить ваш редьюсер
const rootReducer = (state = {}, action) => {
  return state;
};

const store = createStore(rootReducer);

export default store;
