export const lhlvesna23 = [
    { title: 'Гром-2 - Столичные Рыцари', data: '28.05.2023', url: 'https://www.youtube.com/embed/dsBYnLcOxTE', tags: ['Группа'] },
    { title: 'Топ Ган - Гром-2', data: '20.05.2023', url: 'https://www.youtube.com/embed/S5epzfQE_f8', tags: ['Квалификация'] },
    { title: 'Гром-2 - Jersey 53', data: '14.05.2023', url: 'https://www.youtube.com/embed/nrOBNjBA6HQ', tags: ['Квалификация'] },
    { title: 'Гром-2 - Звездный союз', data: '07.05.2023', url: 'https://www.youtube.com/embed/iBc5WNyqkJM', tags: ['Квалификация'] },

  ];

export const lhlsezon2223 = [
    { title: 'Lightning - Гром-2', data: '30.04.23', url: 'https://www.youtube.com/embed/V9b0q1XbGfs', tags: ['Финал'] },
    { title: 'Grizzly team - Гром-2', data: '07.01.23', url: 'https://www.youtube.com/embed/qJLaOzcP3BE', tags: ['Регулярный чемпионат'] },
    { title: 'ЖХК Легенда - Гром-2', data: '22.10.22', url: 'https://www.youtube.com/embed/Q5AOmMOFIZw', tags: ['Регулярный чемпионат'] },
    { title: 'Black Dragon - Гром-2', data: '08.10.22', url: 'https://www.youtube.com/embed/Fezn0aQvBKI', tags: ['Регулярный чемпионат'] },

  ];

export const teamvideo = [
    { title: 'Секреты Профессии', data: '30.04.23', url: 'https://www.youtube.com/embed/ZfBDnqBdPEI', tags: ['О команде'] },

  ];  
  