import React, { useEffect } from 'react';
import { Typography, Container, Box, Paper, useMediaQuery, useTheme, IconButton } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

import '../assets/HomePage.css';
import team_photo from '../assets/images/team/team_photo.JPG';
import logogrom from '../assets/images/logo/logo_grom.svg';

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
    script.async = true;
    script.onload = () => {
      window.ymaps.ready(() => {
        var myMap = new window.ymaps.Map('map', {
          center: [55.86, 37.49],
          zoom: 10
        }, {
          searchControlProvider: 'yandex#search'
        });

        var arena1 = new window.ymaps.Placemark([55.886958, 37.534092], { hintContent: 'Ледовый Дворец Молния' });
        var arena2 = new window.ymaps.Placemark([55.862323, 37.411499], { hintContent: 'СпортТех Арена Северо-Запад' });

        myMap.geoObjects.add(arena1).add(arena2);
      });
    }
    document.body.appendChild(script);
  }, []);

  return (
    <Container className="HomePage">
      <Box mt={isMobile ? 2 : 4} align='center'>
        <Typography variant={isMobile ? 'h6' : 'h3'} gutterBottom>
          Добро пожаловать на официальный сайт ХК Гром
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Box className="team-photo-wrapper" mb={4}>
          <img src={team_photo} alt="team_photo" className="team-photo" />
          <img src={logogrom} alt="team_logo" className="team-logo" />
        </Box>

        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between">
          <Paper elevation={3} className="home-paper" style={{ marginBottom: theme.spacing(isMobile ? 2 : 0), marginLeft: theme.spacing(isMobile ? 0 : 2) }}>
            <Box p={2}>
              <ScheduleIcon fontSize="large" />
              <Typography variant="h5" gutterBottom>Расписание тренировок</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>День недели</TableCell>
                    <TableCell>Время</TableCell>
                    <TableCell>Арена</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Понедельник</TableCell>
                    <TableCell>22:15 - 23:45</TableCell>
                    <TableCell>
                      <Link to="https://yandex.ru/maps/213/moscow/?ll=37.533336%2C55.885586&mode=poi&poi%5Bpoint%5D=37.534161%2C55.886885&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D208493805013&z=15.87" className="link-text">Ледовый Дворец Молния</Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Среда</TableCell>
                    <TableCell>22:45 - 00:00</TableCell>
                    <TableCell>
                      <Link to="https://stfund.ru/arena2" className="link-text">СпортТех Арена Северо-Запад</Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Пятница</TableCell>
                    <TableCell>21:45 - 23:15</TableCell>
                    <TableCell>
                      <Link to="https://stfund.ru/arena2" className="link-text">СпортТех Арена Северо-Запад</Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Paper>

          <Paper elevation={3} className="home-paper" style={{ marginBottom: theme.spacing(isMobile ? 2 : 0), marginLeft: theme.spacing(isMobile ? 0 : 2) }}>
            <Box p={2}>
              <AnnouncementIcon fontSize="large" />
              <Typography variant="h5" gutterBottom>Новости</Typography>
              <Typography variant="body2" color="text.secondary">Здесь будут новости команды</Typography>
            </Box>
          </Paper>

          <Paper elevation={3} className="home-paper" style={{ marginBottom: theme.spacing(isMobile ? 2 : 0), marginLeft: theme.spacing(isMobile ? 0 : 2) }}>
            <Box p={2}>
              <SportsHockeyIcon fontSize="large" />
              <Typography variant="h5" gutterBottom>Арены</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Арена</TableCell>
                    <TableCell>Адрес</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Link to="https://yandex.ru/maps/213/moscow/?ll=37.533336%2C55.885586&mode=poi&poi%5Bpoint%5D=37.534161%2C55.886885&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D208493805013&z=15.87" className="link-text">Ледовый Дворец Молния</Link>
                    </TableCell>
                    <TableCell>
                      Лобненская ул., 13А, стр. 1, Москва
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Link to="https://stfund.ru/arena2" className="link-text">СпортТех Арена Северо-Запад</Link>
                    </TableCell>
                    <TableCell>
                      Большая Черкизовская ул., 125/1, Москва
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Paper>
        </Box>

        <Box id="map" style={{ width: '100%', height: '400px', marginTop: theme.spacing(4) }} />
        
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" marginTop="40px">
    <Typography variant="h5" gutterBottom>
      Скачать мобильное приложение
    </Typography>
    <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
      <IconButton component={Link} to="https://play.google.com/store/apps/details?id=com.iceperkapp" color="primary" aria-label="Download for Android" target="_blank">
        <AndroidIcon fontSize='large'/>
      </IconButton>
      <IconButton component={Link} to="https://apps.apple.com/ru/app/%D1%85%D0%BE%D0%BA%D0%BA%D0%B5%D0%B9-%D0%BB%D0%B5%D0%B4%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B1%D1%80%D0%B0%D1%82%D1%81%D1%82%D0%B2%D0%BE/id1209958483" color="primary" aria-label="Download for iOS" target="_blank">
        <AppleIcon fontSize='large' />
      </IconButton>
    </Box>
  </Box>

    </Container>
  );
}

export default HomePage;
