import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MenuGrom from './components/MenuGrom';
import HomePage from './components/HomePage';
import HomeVideoPage from './components/HomeVideoPage';
import TeamPage from './components/TeamPage';
import VideoPage from './components/VideoPage';
import {lhlvesna23, lhlsezon2223, teamvideo} from './components/videoData';

const AppRoutes = () => {
  return (
    <Router>
      <Header />
      <MenuGrom/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/mediatournaments" element={<HomeVideoPage />} />
        <Route path="/lhlvesna23" element={<VideoPage data={lhlvesna23}/>} />
        <Route path="/lhlsezon2223" element={<VideoPage data={lhlsezon2223}/>} />
        <Route path="/teamvideo" element={<VideoPage data={teamvideo}/>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
