import Lhl from '../assets/images/logo/logo_lhl.png';
import Tr from '../assets/images/logo/logo_tr.png';
import logogrom from '../assets/images/logo/logo_grom.svg';

import Coach from '../assets/images/people/coach.jpeg';
import Gruzdkovan from '../assets/images/people/gruzdkovan.jpeg';
import Nevskiyds from '../assets/images/people/nevskiyds.jpeg';
import Lastoveckiyms from '../assets/images/people/lastoveckiyms.jpeg';
import Person24 from '../assets/images/people/person24.jpeg';
import Person31 from '../assets/images/people/person31.jpeg';
import Person48 from '../assets/images/people/person48.jpeg';
import Person63 from '../assets/images/people/person63.jpeg';
import Person70 from '../assets/images/people/person70.jpeg';
import Person77 from '../assets/images/people/person77.jpeg';

export const peopleData = [
    { name: 'Никифоров Денис Вадимович', number: "CH", data: "05.07.1995", role: "Тренер", image: Coach, logo_league: [Lhl, Tr] },
    { name: 'Ластовецкий Максим Сергеевич', number: 2, data: "03.07.2001", role: "Нападающий", image: Lastoveckiyms, logo_league: [Lhl, Tr] },
    { name: 'Груздков Александр Николаевич', number: 2, data: "27.06.1984", role: "Вратарь", image: Gruzdkovan, logo_league: [Lhl] },
    { name: 'Невский Дмитрий Сергеевич', number: "4,14", data: "26.01.1982", role: "Нападающий", image: Nevskiyds, logo_league: [Tr] },
    { name: 'Ерофеев Геннадий Викторович', number: 5, data: "04.09.1987", role: "Нападающий", image: logogrom, logo_league: [Tr] },
    { name: 'Спирин Роман Николаевич', number: 6, data: "14.04.1987", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Ащеулов Геннадий Николаевич', number: 7, data: "02.11.1959", role: "Нападающий", image: logogrom, logo_league: [Lhl]  },
    { name: 'Ащеулов Алексей Геннадьевич', number: 8, data: "25.03.1985", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Орлов Алексей Александрович', number: 9, data: "07.03.1987", role: "Защитник", image: logogrom, logo_league: [Lhl, Tr] },
  
    { name: 'Костин Дмитрий Анатольевич', number: 10, data: "22.11.1977", role: "Защитник", image: logogrom, logo_league: [Lhl] },
    { name: 'Колыхалов Роман Юрьевич', number: 11, data: "11.04.1983", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Журавлев Сергей Владимирович', number: 12, data: "11.04.1986", role: "Нападающий", image: logogrom, logo_league: [Lhl] },
    { name: 'Тимофеев Савва Владимирович', number: 13, data: "25.10.2004", role: "Защитник", image: logogrom, logo_league: [Lhl] },
    { name: 'Кривельский Алексей Игоревич', number: 14, data: "02.10.1991", role: "Нападающий", image: logogrom, logo_league: [Lhl] },
    { name: 'Иванов Александр Николаевич', number: 15, data: "15.03.1985", role: "Защитник", image: logogrom, logo_league: [Lhl] },
    { name: 'Остапенко Сергей Васильевич', number: 16, data: "16.05.1995", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Бухарбаев Тимур Радолевич', number: 17, data: "31.03.1975", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Писаренков Александр Евгеньевич', number: 18, data: "18.06.1988", role: "Защитник", image: logogrom, logo_league: [Lhl] },
    { name: 'Комаров Иван Андреевич', number: 19, data: "19.01.1998", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
  
    { name: 'Зимников Дмитрий Сергеевич', number: 21, data: "22.08.1983", role: "Защитник", image: logogrom, logo_league: [Lhl] },
    { name: 'Савельев Александр Александрович', number: 22, data: "28.06.1971", role: "Нападающий", image: logogrom, logo_league: [Tr] },
    { name: 'Дробышев Александр Викторович', number: 23, data: "23.03.1986", role: "Защитник", image: logogrom, logo_league: [Lhl] },
    { name: 'Фетеля Иван Викторович', number: 23, data: "07.01.1998", role: "Нападающий", image: logogrom, logo_league: [Lhl] },
    { name: 'Ластовецкий Сергей Николаевич', number: 24, data: "20.11.1974", role: "Нападающий", image: Person24, logo_league: [Lhl, Tr] },
    { name: 'Трифонов Дмитрий Юрьевич', number: 25, data: "25.12.1984", role: "Нападающий", image: logogrom, logo_league: [Lhl] },
    { name: 'Жилич Алексей Станиславович', number: 28, data: "28.10.1988", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
  
    { name: 'Климов Николай Борисович', number: 31, data: "31.01.1989", role: "Нападающий", image: Person31, logo_league: [Lhl]  },
    { name: 'Ершов Сергей Александрович', number: 31, data: "27.01.1982", role: "Защитник", image: logogrom, logo_league: [Tr] },
    { name: 'Васильев Никита Дмитриевич', number: 32, data: "15.09.1997", role: "Защитник", image: logogrom, logo_league: [Tr] },
    { name: 'Борок Михаил Владимирович', number: 33, data: "18.02.1984", role: "Защитник", image: logogrom, logo_league: [Tr]  },
    { name: 'Чинёнов Виктор Владимирович', number: 34, data: "20.04.1983", role: "Защитник", image: logogrom, logo_league: [Lhl]  },
    { name: 'Бачурин Евгений Евгеньевич', number: 35, data: "16.04.1986", role: "Защитник", image: logogrom, logo_league: [Lhl, Tr]  },
    { name: 'Березин-Юшин Павел Александрович', number: 38, data: "24.03.1983", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Шайков Дмитрий Игоревич', number: 39, data: "25.05.1992", role: "Нападающий", image: logogrom, logo_league: [Tr] },
  
    { name: 'Елисеев Роман Михайлович', number: "3,40", data: "10.08.1982", role: "Защитник", image: logogrom, logo_league: [Lhl, Tr]  },
    { name: 'Кунгурцев Артем Николаевич', number: 43, data: "02.05.1982", role: "Нападающий", image: Person48, logo_league: [Lhl]  },
    { name: 'Данилюк Дмитрий Анатольевич', number: 44, data: "02.03.1973", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Онипенко Михаил Сергеевич', number: 47, data: "17.07.1981", role: "Нападающий", image: logogrom, logo_league: [Lhl]  },
  
    { name: 'Бурба Владимир Александрович', number: 55, data: "14.09.1987", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
  
    { name: 'Табагари Иван Васильевич', number: 63, data: "06.03.1996", role: "Нападающий", image: Person63, logo_league: [Lhl]  },
    { name: 'Хмельницкий Максим Владимирович', number: 66, data: "20.09.1984", role: "Нападающий", image: logogrom, logo_league: [Lhl]  },
    { name: 'Лебедев Александр Михайлович', number: "11,68", data: "12.11.1986", role: "Нападающий", image: logogrom, logo_league: [Lhl,Tr] },
  
    { name: 'Кутепов Дмитрий Михайлович', number: 70, data: "28.03.1992", role: "Нападающий", image: Person70, logo_league: [Lhl, Tr] },
    { name: 'Бубнов Сергей Александрович', number: 72, data: "11.09.1986", role: "Защитник", image: logogrom, logo_league: [Lhl] },
    { name: 'Пестряков Александр Евгеньевич', number: 73, data: "31.10.1973", role: "Защитник", image: logogrom, logo_league: [Lhl]  },
    { name: 'Драницын Александр Владимирович', number: 76, data: "16.07.1976", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Старостин Илья Владимирович', number: 77, data: "13.11.1977", role: "Защитник", image: Person77, logo_league: [Lhl, Tr] },
    { name: 'Свиридов Дмитрий Александрович', number: 78, data: "23.07.1997", role: "Нападающий", image: logogrom, logo_league: [Tr] },
    { name: 'Ерпылев Вячеслав Владимирович', number: 78, data: "28.06.1978", role: "Нападающий", image: logogrom, logo_league: [Lhl] },
  
    { name: 'Дудченко Илья Юрьевич', number: 81, data: "04.04.1981", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Чванов Александр Сергеевич', number: 82, data: "11.02.1982", role: "Нападающий", image: logogrom, logo_league: [Lhl] },
    { name: 'Бакланов Егор Сергеевич', number: 82, data: "14.10.2004", role: "Вратарь", image: logogrom, logo_league: [Lhl] },
    { name: 'Пирогов Дмитрий Алексеевич', number: 83, data: "01.03.1978", role: "Вратарь", image: logogrom, logo_league: [Tr] },
    { name: 'Подовинников Иван Евгеньевич', number: "7,85", data: "26.01.1985", role: "Защитник", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Драницын Андрей Владимирович', number: 86, data: "17.09.1986", role: "Защитник", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Спирин Константин Николаевич', number: 87, data: "01.12.1980", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Сологуб Леонид Вадимович', number: 88, data: "22.12.1968", role: "Нападающий", image: logogrom, logo_league: [Lhl]  },
  
    { name: 'Оборотнев Артём Евгеньевич', number: 91, data: "21.04.1996", role: "Нападающий", image: logogrom, logo_league: [Lhl]  },
    { name: 'Максименко Илья Дмитриевич', number: 92, data: "03.08.1998", role: "Вратарь", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Бакланов Сергей Викторович', number: 92, data: "08.11.1970", role: "Вратарь", image: logogrom, logo_league: [Lhl] },
    { name: 'Свиридов Александр Владимирович', number: 96, data: "30.05.1973", role: "Нападающий", image: logogrom, logo_league: [Lhl] },
    { name: 'Лукьянчиков Михаил Леонидович', number: 97, data: "15.10.1972", role: "Нападающий", image: logogrom, logo_league: [Lhl] },
    { name: 'Волков Михаил Александрович', number: 97, data: "21.11.1982", role: "Защитник", image: logogrom, logo_league: [Lhl] },
    { name: 'Метелкин Игорь Викторович', number: 98, data: "11.12.1978", role: "Нападающий", image: logogrom, logo_league: [Lhl, Tr] },
    { name: 'Касаткин Дмитрий Михайлович', number: 99, data: "06.11.1988", role: "Вратарь", image: logogrom, logo_league: [Lhl, Tr] },
  
  ];

export default peopleData;
