import * as React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

import logolhl from '../assets/images/logo/logo_lhl.png';
import logotr from '../assets/images/logo/logo_tr.png';

import '../assets/Header.css';

const Header = () => {
  const [anchorElClubs, setAnchorElClubs] = React.useState(null);
  const [anchorElSites, setAnchorElSites] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpenClubs = (event) => {
    setAnchorElClubs(event.currentTarget);
  };

  const handleMenuOpenSites = (event) => {
    setAnchorElSites(event.currentTarget);
  };

  const handleCloseClubs = () => {
    setAnchorElClubs(null);
  };

  const handleCloseSites = () => {
    setAnchorElSites(null);
  };

  const menuItems = [
    { name: 'Турниры', to: '/mediatournaments', onClick: handleCloseClubs },
    { name: 'Состав ХК "Гром"', to: '/team', onClick: handleCloseSites },
  ];

  const menuButtons = [
    { name: 'Медиа', anchorEl: anchorElClubs, handleOpen: handleMenuOpenClubs, handleClose: handleCloseClubs },
    { name: 'О клубе', anchorEl: anchorElSites, handleOpen: handleMenuOpenSites, handleClose: handleCloseSites },
  ];

  const MenuItemLink = styled(Link)({
    textDecoration: 'none',
    color: '#ccc',
  });

  const toolbarStyles = isMobile ? { flexDirection: "row" } : { flexDirection: "row" };

  return (
    <div className="Header">
      <AppBar position="static" style={{ backgroundColor: '#242F37' }}>
        <Toolbar style={toolbarStyles}>
          <Box display="flex" alignItems="center">
            <Link to="https://lhl-77.ru/clubs/434" target="_blank">
              <img className="logo_header" src={logolhl} alt="logolhl" />
            </Link>
            <Link to="https://hltr.ru/teams/2768" target="_blank">
              <img className="logo_header" src={logotr} alt="logotr" />
            </Link>
          </Box>
          <Box display="flex" flexDirection={isMobile ? "row" : "row"}>
            {menuButtons.map((button) => (
              <div className="menu-item" key={button.name}>
                <IconButton onClick={button.handleOpen} color="inherit">
                  <Typography variant="h6" className="menu-item-text" sx={{ '@media (max-width:599px)': { fontSize: '12px' }}}>{button.name}</Typography>
                  <ArrowDropDownIcon />
                </IconButton>
                <Menu
                  anchorEl={button.anchorEl}
                  open={Boolean(button.anchorEl)}
                  onClose={button.handleClose}
                >
                  {menuItems.filter(item => item.onClick === button.handleClose).map((item) => (
                    <MenuItem sx={{ '@media (max-width:599px)': { fontSize: '12px' }}} key={item.name} onClick={item.onClick}>
                      <MenuItemLink to={item.to || '#'}>{item.name}</MenuItemLink>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
