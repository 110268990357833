import React, { useState, useMemo } from 'react';
import { Card, CardContent, Typography, Box, Avatar, AvatarGroup, CardActionArea, Tab } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import { styled } from '@mui/system';
import '../assets/TeamPage.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import peopleData from './peopleData.js';

// Импортируем необходимые компоненты
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

const Logo = styled(Avatar)({
  position: 'absolute',
  top: '65%',
  left: '90%',
  transform: 'translate(-50%, -50%)',
  width: '60px',
  height: '60px',
  fontSize: '24px',
  backgroundColor: '#c9ab49',
  color: '#fff',
});

const StyledCardMedia = styled('img')({
    width: '100%', // Обеспечиваем максимальную ширину 100%
    height: 'auto', // Высота автоматически изменяется в соответствии с пропорциями изображения
    objectFit: 'cover', // Изображение обрежется и масштабируется так, чтобы заполнить коробку, сохраняя при этом свои пропорции
});

const TeamPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tab, setTab] = useState('1');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const people = useMemo(() => peopleData, [peopleData]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%', 
    maxWidth: '345px',
    margin: theme.spacing(2, 'auto'),
    '&:hover': {
      boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    },
  }));
  

  const staff = people.filter(person => person.role === "Тренер");
  const goalkeepers = people.filter(person => person.role === "Вратарь");
  const forwards = people.filter(person => person.role === "Нападающий");
  const defenders = people.filter(person => person.role === "Защитник");

  return (
    <div>
      <Typography variant="h4" component="h2" gutterBottom style={{textAlign: 'center'}}>
        Состав ХК "Гром"
      </Typography>

      <Box display={isMobile ? 'block' : 'flex'} flexWrap="wrap" justifyContent="center">
      <TableContainer className="center-table-content">
        <Table>
          <TableHead className="center-table-headers">
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Номер</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Дата рождения</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Лига</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {people.map((person, index) => (
              (index >= page * rowsPerPage && index < page * rowsPerPage + rowsPerPage) &&
              <TableRow key={person.name}>
                <TableCell>
                  <Avatar src={person.image} />
                </TableCell>
                <TableCell>{person.number}</TableCell>
                <TableCell>{person.name}</TableCell>
                <TableCell>{person.data}</TableCell>
                <TableCell>{person.role}</TableCell>
                <TableCell>
                  <div className="logo-container">
                    <AvatarGroup max={4}>
                      {person.logo_league &&
                        person.logo_league.map((logo, index) => <Avatar key={index} src={logo} />)}
                    </AvatarGroup>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          className="pagination"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={people.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      </Box>

      <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <TabList 
    onChange={handleTabChange} 
    aria-label="lab API tabs example" 
    variant="scrollable"
    scrollButtons="auto"
  >
    <Tab label="Штаб" value="1" />
    <Tab label="Вратари" value="2" />
    <Tab label="Нападающие" value="3" />
    <Tab label="Защитники" value="4" />
  </TabList>
</Box>

        <TabPanel value="1">
          <Typography variant="h5" component="h3" gutterBottom style={{textAlign: 'center'}}>
            Штаб
          </Typography>
          <Box display="grid" gridTemplateColumns={{ xs: 'repeat(auto-fill, minmax(200px, 1fr))', md: 'repeat(auto-fill, minmax(345px, 1fr))' }} gap={2}>
            {staff.map((person) => (
              <StyledCard key={person.name}>
                <CardActionArea>
                  <Logo>{person.number}</Logo>
                  <StyledCardMedia
                    src={person.image}
                    title={person.name}
                    alt={person.name}
                    onError={(e)=>{e.target.onerror = null; e.target.src="path/to/default/img"}}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {person.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {person.data}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {person.role}
                    </Typography>
                    <AvatarGroup max={4}>
                      {person.logo_league &&
                        person.logo_league.map((logo, index) => <Avatar key={index} src={logo} />)}
                    </AvatarGroup>
                  </CardContent>
                </CardActionArea>
              </StyledCard>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Typography variant="h5" component="h3" gutterBottom style={{textAlign: 'center'}}>
            Вратари
          </Typography>
          <Box display="grid" gridTemplateColumns={{ xs: 'repeat(auto-fill, minmax(200px, 1fr))', md: 'repeat(auto-fill, minmax(345px, 1fr))' }} gap={2}>
            {goalkeepers.map((person) => (
              <StyledCard key={person.name}>
                <CardActionArea>
                  <Logo>{person.number}</Logo>
                  <StyledCardMedia
                    src={person.image}
                    title={person.name}
                    alt={person.name}
                    onError={(e)=>{e.target.onerror = null; e.target.src="path/to/default/img"}}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {person.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {person.data}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {person.role}
                    </Typography>
                    <AvatarGroup max={4}>
                      {person.logo_league &&
                        person.logo_league.map((logo, index) => <Avatar key={index} src={logo} />)}
                    </AvatarGroup>
                  </CardContent>
                </CardActionArea>
              </StyledCard>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value="3">
          <Typography variant="h5" component="h3" gutterBottom style={{textAlign: 'center'}}>
            Нападающие
          </Typography>
          <Box display="grid" gridTemplateColumns={{ xs: 'repeat(auto-fill, minmax(200px, 1fr))', md: 'repeat(auto-fill, minmax(345px, 1fr))' }} gap={2}>
            {forwards.map((person) => (
              <StyledCard key={person.name}>
                <CardActionArea>
                  <Logo>{person.number}</Logo>
                  <StyledCardMedia
                    src={person.image}
                    title={person.name}
                    alt={person.name}
                    onError={(e)=>{e.target.onerror = null; e.target.src="path/to/default/img"}}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {person.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {person.data}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {person.role}
                    </Typography>
                    <AvatarGroup max={4}>
                      {person.logo_league &&
                        person.logo_league.map((logo, index) => <Avatar key={index} src={logo} />)}
                    </AvatarGroup>
                  </CardContent>
                </CardActionArea>
              </StyledCard>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value="4">
          <Typography variant="h5" component="h3" gutterBottom style={{textAlign: 'center'}}>
            Защитники
          </Typography>
          <Box display="grid" gridTemplateColumns={{ xs: 'repeat(auto-fill, minmax(200px, 1fr))', md: 'repeat(auto-fill, minmax(345px, 1fr))' }} gap={2}>
            {defenders.map((person) => (
              <StyledCard key={person.name}>
                <CardActionArea>
                  <Logo>{person.number}</Logo>
                  <StyledCardMedia
                    src={person.image}
                    title={person.name}
                    alt={person.name}
                    onError={(e)=>{e.target.onerror = null; e.target.src="path/to/default/img"}}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {person.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {person.data}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {person.role}
                    </Typography>
                    <AvatarGroup max={4}>
                      {person.logo_league &&
                        person.logo_league.map((logo, index) => <Avatar key={index} src={logo} />)}
                    </AvatarGroup>
                  </CardContent>
                </CardActionArea>
              </StyledCard>
            ))}
          </Box>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default TeamPage;
