import React, { useState } from 'react';
import { Grid, Card, Typography, Chip, Box, MenuItem, Button, Menu } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const VideoCard = ({ title, url, tags, onTagClick }) => (
  <Card sx={{ maxWidth: 750, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
    <Typography variant="h6" component="div">
      {title}
    </Typography>
    {tags.map(tag => (
      <Chip key={tag} label={tag} onClick={() => onTagClick(tag)} />
    ))}
    <div style={{ position: 'relative', paddingBottom: '56.25%' /* 16:9 */, height: 0 }}>
      <iframe
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        title={title}
        src={url}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </div>
  </Card>
);

const VideoList = ({ data }) => {
  const [selectedTag, setSelectedTag] = useState('');
  const allTags = [...new Set(data.flatMap(video => video.tags))];
  const handleTagChange = (tag) => {
    setSelectedTag(tag);
  }
  
  const filteredVideos = selectedTag
    ? data.filter(video => video.tags.includes(selectedTag))
    : data;

  const [navigationAnchorEl, setNavigationAnchorEl] = React.useState(null);
  const openNavigationMenu = (event) => {
    setNavigationAnchorEl(event.currentTarget);
  };
  const closeNavigationMenu = () => {
    setNavigationAnchorEl(null);
  };

  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const openFilterMenu = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const closeFilterMenu = () => {
    setFilterAnchorEl(null);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} >
        <Box display="flex"  alignItems="center" marginBottom="16px">
          <ThemeProvider
            theme={createTheme({
              palette: {
                mode: 'dark',
                primary: { main: 'rgb(102, 157, 246)' },
              },
            })}
          >
            <Button onClick={openNavigationMenu}>
              Навигация
            </Button>
            <Menu
              id="navigation-menu"
              anchorEl={navigationAnchorEl}
              keepMounted
              open={Boolean(navigationAnchorEl)}
              onClose={closeNavigationMenu}
            >
              <MenuItem component={Link} to="/mediatournaments" onClick={closeNavigationMenu} >Все медиа</MenuItem>
              <MenuItem component={Link} to="/lhlvesna23" onClick={closeNavigationMenu}>ЧЕМПИОНАТ ВЕСНА-2023</MenuItem>
              <MenuItem component={Link} to="/lhlsezon2223" onClick={closeNavigationMenu}>9 СЕЗОН ЛХЛ-77</MenuItem>
              <MenuItem component={Link} to="/teamvideo" onClick={closeNavigationMenu}>ХК Гром</MenuItem>
            </Menu>
          </ThemeProvider>
          <ThemeProvider
            theme={createTheme({
              palette: {
                mode: 'dark',
                primary: { main: 'rgb(102, 157, 246)' },
              },
            })}
          >
            <Button onClick={openFilterMenu}>
              Фильтр
            </Button>
            <Menu
              id="filter-menu"
              anchorEl={filterAnchorEl}
              keepMounted
              open={Boolean(filterAnchorEl)}
              onClose={closeFilterMenu}
            >
              <MenuItem onClick={() => handleTagChange('')}>
                <em>Все видео</em>
              </MenuItem>
              {allTags.map(tag => (
                <MenuItem key={tag} onClick={() => handleTagChange(tag)}>{tag}</MenuItem>
              ))}
            </Menu>
          </ThemeProvider>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={2}>
          {filteredVideos.map(video => (
            <Grid item xs={12} md={6} key={video.title}>
              <VideoCard
                title={video.title}
                url={video.url}
                tags={video.tags}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const VideoPage = ({ data }) => <VideoList data={data} />;
export default VideoPage;
