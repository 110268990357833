import React, { useMemo } from 'react';
import { Card, CardContent, Typography, Box, Avatar, AvatarGroup, CardActionArea } from '@mui/material';
import { styled } from '@mui/system';
import '../assets/TeamPage.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import tournamentData from './tournamentData.js';
import { useNavigate } from 'react-router-dom';


const StyledCardMedia = styled('img')({
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
});

const HomeVideoPage = () => {
    const navigate = useNavigate();

  const tournament = useMemo(() => tournamentData, [tournamentData]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%', 
    maxWidth: '345px',
    margin: theme.spacing(2, 'auto'),
    '&:hover': {
      boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    },
  }));

  return (
    <div>
      <Box display="grid" gridTemplateColumns={{ xs: 'repeat(auto-fill, minmax(200px, 1fr))', md: 'repeat(auto-fill, minmax(345px, 1fr))' }} gap={2}>
        {tournament.map((tournament) => (
          <StyledCard key={tournament.title} onClick={() => navigate(tournament.path)}>
            <CardActionArea>
              <StyledCardMedia
                src={tournament.image}
                title={tournament.title}
                alt={tournament.title}
                onError={(e)=>{e.target.onerror = null; e.target.src="path/to/default/img"}}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {tournament.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {tournament.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {tournament.data}
                </Typography>
                <AvatarGroup max={4}>
                  {tournament.logo_league &&
                    tournament.logo_league.map((logo, index) => <Avatar key={index} src={logo} />)}
                </AvatarGroup>
              </CardContent>
            </CardActionArea>
          </StyledCard>
        ))}
      </Box>
    </div>
  );
};

export default HomeVideoPage;
