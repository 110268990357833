import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Toolbar, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import logogrom from '../assets/images/logo/logo_grom.svg';
import cup2023 from '../assets/images/logo/cup_2023.png';
import cup2022 from '../assets/images/logo/cup_2022.png';
import cup2021 from '../assets/images/logo/cup_2021.png';
import '../assets/MenuGrom.css';

const MenuGrom = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentCupIndex, setCurrentCupIndex] = useState(0);
  const cupImages = [cup2023, cup2022, cup2021];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCupIndex((prevIndex) => (prevIndex + 1) % cupImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="MenuGrom">
      <AppBar position="static" style={{ backgroundColor: '#092829' }}>
        <Toolbar className="toolbar">
          <Box>
            <Typography variant={isMobile ? 'subtitle1' : 'h5'} className="menu_text">
              ХК "Гром"
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link to="/"> {/* Обновлено: Изменено значение атрибута "to" */}
              <img className={isMobile ? "logo_menu_mobile" : "logo_menu_desktop"} src={logogrom} alt="logogrom" />
            </Link>
          </Box>
          <Box display="flex" alignItems="center" position="relative">
            <img 
              className={isMobile ? "cup_mobile" : "cup_desktop"} 
              src={cupImages[currentCupIndex]} 
              alt={`cup${2023-currentCupIndex}`} 
            />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default MenuGrom;