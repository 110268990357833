import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from './Routes';
import store from './store';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ backgroundColor: '#282c34' }}>
          <Routes />
        </div>
        </ThemeProvider>,
      </Provider>
    );
  }
}

export default App;
