import Lhl from '../assets/images/logo/logo_lhl.png';
import Tr from '../assets/images/logo/logo_tr.png';
import logogrom from '../assets/images/logo/logo_grom.svg';


import lhl_vesna_2023 from '../assets/images/tournaments/lhl_vesna_2023.jpeg';
import lhl_sezon_22_23 from '../assets/images/tournaments/lhl_sezon_22_23.jpeg';



export const tournamentData = [
  { title: 'ЧЕМПИОНАТ ВЕСНА-2023', data: "2023", description: "ЛХЛ-77", image: lhl_vesna_2023, path: '/lhlvesna23', logo_league: [Lhl] },
  { title: '9 СЕЗОН ЛХЛ-77', data: "2022-2023", description: "ЛХЛ-77", image: lhl_sezon_22_23, path: '/lhlsezon2223', logo_league: [Lhl] },
  { title: 'ХК Гром', data: "На все времена", description: "Видео о команде", image: logogrom, path: '/teamvideo', logo_league: [logogrom] },
  
  ];

export default tournamentData;
